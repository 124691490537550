<template>
    <div class="Courseinformation">
        <div class="inform_t">
            <div class="inform_t_div">
                <img src="../../assets/img/tea_page.png" alt="">
                <div class="add_title">
                    <i class="iconfonts icons-tianjia"></i>
                    <span>添加课程</span>
                </div>
                <p class="add_p">
                    您当前未添加任何课程，请先添加课程。
                </p>
                <button class="add_but" v-preventReClick @click="addTeam()">添加课程</button>
                <div class="form-groups">
                    <span></span>
                    <span>状态：
                        <el-select v-model="states" placeholder="请选择状态" size="small" @change="selecrstate(states)">
                            <el-option label="全部" :value="0"></el-option>
                            <el-option label="未开课" :value="1"></el-option>
                            <el-option label="已开课" :value="2"></el-option>
                            <el-option label="已结课" :value="3"></el-option>
                        </el-select>
                    </span>
                    <!-- <span>状态：</span> -->
                    <span>
                    年份：
                        <el-select v-model="year" placeholder="请选择年份" size="small" @change="selecrstates(year)">
                            <el-option label="全部" :value="0"></el-option>
                            <el-option label="2021年" :value="2021"></el-option>
                            <el-option label="2022年" :value="2022"></el-option>
                        </el-select>
                    </span>
                    <span>
                        <el-input v-model="search" placeholder="请输入关键字" size="small"></el-input>
                    </span>
                    <span>
                        <el-button type="primary" size="small" v-preventReClick @click="query()">查询</el-button>
                    </span>
                    <span>
                        <el-button type="warning" size="small" v-preventReClick @click="reset()">重置</el-button>
                    </span>
                </div>
            </div>
        </div>
        <div class="inform_con clearfix">
            <ul class="inform_con_ul clearfix" v-show="infolist.length!=0">
                <li v-for="(item,index) in infolist" :key="index" class="fl">
                    <div class="div_title">
                        <span>{{item.name}}</span>
                    </div>
                    <div class="div_one clearfix">
                        <span class="fl div_one_l">
                            <span class="s_span">课程码</span>：{{item.code}}
                        </span>
                        <span class="fr div_one_r" :class="item.state==1?'item_one':item.state==2?'item_two':item.state==3?'item_three':''">
                            {{item.state==1?'未开课':item.state==2?'已开课':item.state==3?'已结课':''}}
                        </span>
                    </div>
                    <div class="div_two">
                        <span class="div_two_span">
                            <span class="s_span">时间</span>：{{item.start}} 至 {{item.end}}
                        </span>
                    </div>
                    <div class="div_two clearfix">
                        <span class="div_two_l fl">
                            <span>课程类别</span>：A场
                        </span>
                        <span class="div_two_l fl">
                            <span>主讲老师</span>：王某某
                        </span>
                    </div>
                    <div class="div_buttons clearfix">
                        <div class="div_but_l fl" v-preventReClick @click="delBox(item.id)">
                            <i class="iconfonts icons-shanchu"></i>
                            <span>删除</span>
                        </div>
                        <div class="div_but_r fl" v-preventReClick @click="viewBox(item)">
                            <i class="iconfonts icons-fangdajing_huaban"></i>
                            <span>查看</span>
                        </div>
                    </div>
                </li>
            </ul>
            <!-- 课程为空 -->
            <div v-show="infolist==0" class="null_div">
                <img src="./../../assets/img/404.png" alt="">
                <p>您当前未添加课程，请添加课程！</p>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="add_popup">
            <el-dialog
                title="添加课程"
                :visible.sync="dialogVisible"
                :show-close="false"
                width="390px"
                :before-close="handleClose">
                    <i class="el-icon-close closei" v-preventReClick @click="closei('ruleForm')"></i>
                    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" size="small">
                        <el-form-item label="案例选择" prop="caseId">
                            <el-select v-model="ruleForm.caseId" placeholder="请选择案例">
                                <el-option
                                    v-for="item in caseData"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="课堂名称" prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请输入课堂名称"></el-input>
                        </el-form-item>
                        <el-form-item label="班级名称" prop="grade">
                            <el-input v-model="ruleForm.grade" placeholder="请输入班级名称"></el-input>
                        </el-form-item>
                        <el-form-item label="课程时间" prop="Time">
                            <el-date-picker
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                v-model="ruleForm.Time"
                                :clearable="false"
                                type="daterange"
                                align="center"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOption"
                                @change="setDayInfo(ruleForm.Time)">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="总人数" prop="users">
                            <el-input v-model.number="ruleForm.users" placeholder="请输入总人数"></el-input>
                        </el-form-item>
                        <el-form-item label="分组数" prop="teams">
                            <el-input v-model.number="ruleForm.teams" placeholder="请输入分组数"></el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button size="small" v-preventReClick @click="closei('ruleForm')">取 消</el-button>
                        <el-button size="small" type="success" v-preventReClick @click="addPro('ruleForm')">确 定</el-button>
                    </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pickerOption:{
                disabledDate(time) {
                    return time.getTime() < Date.now()- 8.64e7;      //返回的值是true或false，比较的是毫秒数
                }
            },
            dialogVisible:false,
            ruleForm:{
                Time:'',
                caseId:'',
                name:'',
                users:'',
                teams:'',
                Time:'',
                grade:'',            
            },
            rules:{
                caseId:[
                    { required: true, message: '请选择案例', trigger: 'change' }
                ],
                name:[
                    { required: true, message: '请输入课堂名称', trigger: 'blur' }
                ],
                grade:[
                    { required: true, message: '请输入班级名称', trigger: 'blur' }
                ],
                users:[
                    { required: true, message: '请输入学生总数', trigger: 'blur' },
                    { type: 'number', message: '学生总数必须为数字值'}
                ],
                teams:[
                    { required: true, message: '请输入分组数', trigger: 'blur' },
                    { type: 'number', message: '分组数必须为数字值'}
                ],
                Time: [
                   { required: true, message: "请选择课堂时间", trigger: ['blur', 'change']}
                ],
            },
            infolist:[],
            caseData:[],
            search:'',
            year:0,
            states:0,
        }
    },
    methods: {
        getapp(){
            this.axios.Teacasepage({
                token:this.$store.state.token,
                state:this.states,
                year:this.year,
                search:this.search
            }).then(res=>{
                if(res.data.code==0){
                    this.infolist = res.data.data.rows
                }else{
                    this.$message.error(res.data.message)
                    this.infolist = []
                }
            }).catch(err=>{

            })
        },
        //添加课程查询案例
        addTeam(){
            this.axios.TeacaseList({
                token:this.$store.state.token,
            }).then(res=>{
                if(res.data.code==0){
                    this.caseData = res.data.data
                    this.dialogVisible = true
                }else{
                    this.$message.error(res.data.message)
                    this.caseData = []
                }
            }).catch(err=>{
                    
            })
        },
        //选择时间
        setDayInfo(val){
            this.ruleForm.start = val[0]
            this.ruleForm.end = val[1]
        },
        // 添加案例
        addPro(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.token = this.$store.state.token
                    this.axios.Teacreate(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==0){
                            this.$message.success('添加成功')
                            this.dialogVisible = false
                            this.$refs[formName].resetFields();
                            this.getapp()
                        }else{
                            this.$message.info(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //删除课堂
        delBox(val){
            this.$confirm('此操作永久删除该记录，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.TeacreateDel({
                    token:this.$store.state.token,
                    id:val
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 通过状态查询
        selecrstate(val){
            this.states = val
            this.getapp()
        },
        // 通过年份查询
        selecrstates(val){
            this.year = val
            this.getapp()
        },
        // 关键字查询
        query(){
            this.search = this.search
            this.getapp()
        },
        // 重置
        reset(){
            this.search = ''
            this.states = 0
            this.year = 0
            this.getapp()
        },
        //查看详情
        viewBox(val){
            this.$router.push({name:'Coursedetails',query:{calId:val.id,num:1}})
        },
        handleClose(done){

        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
    },
    mounted() {
        setTimeout(()=> {
            this.getapp()
        }, 500);
    },
}
</script>
<style scoped>
@import './../../assets/css/teacher/coursePage.css';
</style>
<style>
.form-groups .el-input__inner{
    width: 170px;
}
.add_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.add_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.add_popup .el-dialog__body{
    padding: 20px 45px 10px;
}
.add_popup .el-form-item__label{
    font-size: 14px;
    font-weight: bold;
    color: #333;
    line-height: 30px;
}
.add_popup .el-form-item{
    margin-bottom: 15px;
}
.add_popup .el-dialog__footer{
    text-align: center;
}
.add_popup .el-button{
    width: 98px;
    font-size: 14px;
}
.add_popup .el-button--success{
    background: #05D1C6;
    border-color: #05D1C6;
}
.add_popup .el-range-editor.el-input__inner{
    width: 100%;
}
.add_popup .el-select{
    width: 100%;
}
</style>